<script>
  import { _ } from "svelte-i18n";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import {
    userId,
    userName,
    assessmentType,
    assessmentId,
    assessmentState,
    finalScoreDetail,
    activationCode,
  } from "../utilities/DataStore";
  import { now } from "svelte/internal";
  import ResultReport from "./components/ResultReport.svelte";
  import ResultReportNoData from "./components/ResultReportNoData.svelte";
  import FeedbackModal from "./components/FeedbackModal.svelte";

  onMount(async () => {
    initialize();
  });

  export let instanceId;

  let instanceData;
  let exerciseListStatus;
  let reviewCommentData;

  let errorMsg;

  function initialize() {
    Promise.all([loadInstanceData()])
      .then(() => {})
      .catch(() => {
        console.log("reloading");
        setTimeout(function () {
            loadInstanceData();
          }, 1500);        
      });
  }

  async function loadInstanceData() {
    exerciseListStatus = "LOADING";
    return fetch(BASE_URL + `/public/assessment/instance/` + instanceId)
      .then((response) => {
        if (!response.ok) {
          console.log("response error");
          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        instanceData = data;
        if (instanceData.reviewComment != null) {
          $finalScoreDetail = JSON.parse(instanceData.reviewComment);
        }

        if (instanceData.status != "SCORED") {
          throw new Error("Assesment not yet scored");
        }

        console.log(
          Date.parse(instanceData?.createdAt) /
            Date.parse(instanceData?.deadlineAt)
        );
        console.log("instanceData", instanceData);
        exerciseListStatus = "OK";

        mixpanel.track("Result", {
          assessmentId: $assessmentId,
          instanceId: instanceId,
          assessmentState: $assessmentState,
          sumScoreTotal: $finalScoreDetail?.sumScoreTotal,
        });
      })
      .catch((error) => {
        console.log("catch error", error);
          errorMsg = "ERROR_LOADING_ASESSMENT_INSTANCE";
          instanceData = undefined;
          //exerciseListStatus = "ERROR";
          setTimeout(function () {
            loadInstanceData();
          }, 1500);        
          // throw new Error("Some Error, try again"); 
      });
  }
</script>

<div class="container">
  <div class="row align-items-center" style="display: none;">
    <div class="col">
      <div class="lesson-title">
        {$assessmentType}
      </div>
    </div>
    <div class="col-auto lesson-detail-box">
      <div class="lesson-detail float-right">
        <div>Assessment: {$assessmentId}</div>
        <div>Instance: {instanceId}</div>
        <div>userName: {$userName}</div>
        <div>UserId: {$userId}</div>
        <div>State: {$assessmentState}</div>
      </div>
    </div>
  </div>

  <div class="card-group">
    {#if exerciseListStatus === "NEW"}
      Loading
    {:else if exerciseListStatus === "LOADING"}
      Loading
    {:else if exerciseListStatus === "ERROR"}
      Error loading lesson
    {:else if instanceData}
      {#if instanceData?.assessmentDefinitionCode === "INTRO1"}
        <ResultReportNoData {instanceId} {instanceData} />
      {:else}
        <ResultReport {instanceId} {instanceData} />
      {/if}

      <FeedbackModal />
    {/if}
  </div>
</div>

<style>
  @media (max-width: 768px) {
    .lesson-detail-box {
      display: none;
    }
    .container {
      padding: 0;
    }
  }

  .card-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .lesson-title {
    font-weight: 600;
    font-size: large;
  }

  .lesson-detail {
    border: 1px;
    border-color: #9d9fa2;
    border-style: solid;
    border-radius: 5px;
    margin: 0.5em;
    padding: 0.5em;
  }
</style>
