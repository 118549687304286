<script>
  import { _ } from "svelte-i18n";
  import { BASE_URL } from "../utilities/config.js";
  import SpeechToTextOpus from "./components/SpeechToTextOpus.svelte";
  import TextToSpeech2 from "./components/TextToSpeech2.svelte";
  import Instructions from "./components/Instructions.svelte";
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import {
    currentPage,
    userId,
    userName,
    assessmentId,
    assessmentDefinitionId,
    assessmentType,
    assessmentState,
    lastRecording,
  } from "../utilities/DataStore";
  import {
    uploadMedia,
    uploadExerciseResults,
  } from "./components/UploadResults.svelte";

  export let instanceId;
  export let exerciseId;

  onMount(async () => {
    $lastRecording = undefined;
    loadData();

    if (!!window.SpeechSDK) {
      SpeechSDK = window.SpeechSDK;
      ttsButtonState = "starting";
      initPlayPage();
    } else {
      ttsButtonState = "error";
      console.log("error with SpeechSDK");
    }

    soundEffect = new Howl({
      src: ["./assets/audio/prel_musical_65.mp3"],
      volume: 0.5,
      preload: true,
    });
  });
  let exerciseStatus = "NEW";
  let exerciseDetail;
  let exerciseDetailData;
  let errorMsg;

  let parts = [];
  let aggregateScore;

  let speechToTextOpus = undefined;
  let audioControlDuration = writable(undefined);
  let recognitionResults = [];
  let recognitionResultsFinal = writable([]);
  let ttsButtonState;
  let recognizer;

  let ttsInstance = undefined;

  let ttsToken;
  let textToDisplay;
  let textToRead;
  let soundEffect;

  async function getToken(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
        "Content-Type": "application/x-www-form-urlencoded",
        ...data,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    console.log("getToken", response);
    //uploadStatus.innerHTML = response.status;
    return response.text(); // parses JSON response into native JavaScript objects
  }

  function initPlayPage() {
    // getNewToken();
    getToken("https://api.oksensay.com/public/api/azureToken2/1")
      .then((data) => {
        console.log("token", data);
        ttsToken = data;
        ttsButtonState = "ready";
      })
      .catch((error) => {
        console.log("token", error);
        ttsButtonState = "error";
      });
  }

  // async function getNewToken() {
  //   ttsToken = await ky
  //     .get("https://api.oksensay.com/public/api/azureToken2/1", {
  //       retry: 3,
  //       timeout: 30000,
  //     })
  //     .text();
  //   ttsButtonState = "ready";
  // }

  function loadData() {
    exerciseStatus = "LOADING";
    fetch(
      BASE_URL +
        `/public/assessment/definition/` +
        $assessmentDefinitionId +
        `/exercise/` +
        exerciseId
    )
      .then((response) => {
        if (!response.ok) {
          console.log("response error");

          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        exerciseDetail = data;
        exerciseDetailData = JSON.parse(exerciseDetail.data);
        console.log("exerciseDetail", exerciseDetail);
        console.log("exerciseDetailData", exerciseDetailData);
        exerciseStatus = "OK";
      })
      .catch((error) => {
        console.log("catch error", error);
        errorMsg = "ERROR_LOADING_EXERCISE";
        exerciseDetail = undefined;
        exerciseStatus = "ERROR";
      });
  }

  function startRecognition() {
    console.log("startRecognition stopTextToSpeech");
    if (ttsInstance) {
      ttsInstance.stopTextToSpeech();
    }

    console.log("startRecognition");
    $recognitionResultsFinal = [];
    recognitionResults.push("START");
    recognitionResults = recognitionResults;
    fromMic();
    speechToTextOpus.startRecording();
  }

  async function stopRecognitionTimeout() {
    console.log("stopRecognitionTimeout");
    ttsButtonState = "timeout";
    recognitionResults.push("TIMEOUT");
    recognitionResults.push("STOP");

    recognizer.speechEndDetected = () => {
      console.log(`speechEndDetected`);
      recognitionResults = recognitionResults;
      formatResult($recognitionResultsFinal);
      soundEffect.play();
      ttsButtonState = "done";
      recognitionResults.push("DONE");

      submitResult();
    };
    recognizer.stopContinuousRecognitionAsync();
  }

  async function stopRecognition(fromTimer = false) {
    console.log("stopRecognition");
    ttsButtonState = "stop";
    recognitionResults.push("STOP");
    speechToTextOpus.stopRecording();

    //await new Promise((r) => setTimeout(r, 2000));

    recognizer.speechEndDetected = () => {
      console.log(`speechEndDetected`);
      recognitionResults = recognitionResults;
      formatResult($recognitionResultsFinal);
      soundEffect.play();
      ttsButtonState = "done";
      recognitionResults.push("DONE");

      submitResult();
    };
    recognizer.stopContinuousRecognitionAsync();
  }

  function submitRetry() {
    ttsButtonState = "done";
    submitResult();
  }

  function submitResult() {
    console.log("submitResults");

    Promise.all([
      uploadMedia(instanceId, exerciseId, $lastRecording.blob),
      uploadExerciseResults(
        instanceId,
        exerciseId,
        aggregateScore,
        $recognitionResultsFinal
      ),
    ])
      .then(() => {
        console.log("Successfully sent data");
        //router.goto("/assessment/" + instanceId);
        $currentPage = "AssessmentOverview";
      })
      .catch(() => {
        ttsButtonState = "error";
      });
  }

  function fromMic() {
    let speechConfig;

    let textToRead = exerciseDetailData?.text ? exerciseDetailData.text : "";

    if (ttsToken) {
      speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
        ttsToken,
        "eastasia"
      );
    } else {
      if (ttsToken === "" || ttsToken === "subscription") {
        alert(
          "Please enter your Microsoft Cognitive Services Speech subscription key!"
        );
        return;
      }
      speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        subscriptionKey.value,
        serviceRegion.value
      );
    }

    speechConfig.speechRecognitionLanguage = exerciseDetail?.language
      ? exerciseDetail.language
      : "en-US";
    //speechConfig.setProfanity(2);

    var pronunciationAssessmentConfig =
      new SpeechSDK.PronunciationAssessmentConfig(
        textToRead,
        SpeechSDK.PronunciationAssessmentGradingSystem.HundredMark,
        SpeechSDK.PronunciationAssessmentGranularity.Phoneme,
        true
      );

    let audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);

    pronunciationAssessmentConfig.applyTo(recognizer);

    console.log("Speak into your microphone.");

    recognizer.recognizing = (s, e) => {
      console.log(`RECOGNIZING: Text=${e.result.text}`);
    };

    recognizer.speechStartDetected = () => {
      console.log(`speechStartDetected`);
      ttsButtonState = "recording";
      //recognitionResults.push({ speechStartDetected: e?.result });
    };

    recognizer.speechEndDetected = () => {
      console.log(`speechEndDetected`);
      //recognitionResults.push({ speechEndDetected: e?.result });
    };

    recognizer.recognized = (s, e) => {
      if (e.result.reason == SpeechSDK.ResultReason.RecognizedSpeech) {
        console.log(`RECOGNIZED: Text=${e.result.text}`);
        recognitionResults.push({ recognized: e?.result });

        var pronunciationAssessmentResult = JSON.parse(e.result.json);
        recognitionResults.push({
          pronunciationAssessmentResult: pronunciationAssessmentResult,
        });
        recognitionResults = recognitionResults;

        $recognitionResultsFinal.push(pronunciationAssessmentResult);
        $recognitionResultsFinal = $recognitionResultsFinal;
      } else if (e.result.reason == SpeechSDK.ResultReason.NoMatch) {
        console.log("NOMATCH: Speech could not be recognized.");
      }
    };

    recognizer.canceled = (s, e) => {
      console.log(`CANCELED: ${JSON.stringify(e)}`);
      recognizer.stopContinuousRecognitionAsync();
    };

    ttsButtonState = "wait";
    recognizer.startContinuousRecognitionAsync();
  }

  function formatResult(r) {
    console.log("results", r);
    const sum = (arr) => arr.reduce((p, c) => p + c, 0);
    const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

    parts = r.flatMap((part) => {
      if (part?.NBest.length > 0) {
        if (part.NBest[0].PronunciationAssessment) {
          return {
            recognitionStatus: part.RecognitionStatus,
            offset: part.Offset,
            duration: part.Duration,
            displayText: part.DisplayText,
            confidence: part.NBest[0].Confidence,
            accuracyScore: part.NBest[0].PronunciationAssessment.AccuracyScore,
            fluencyScore: part.NBest[0].PronunciationAssessment.FluencyScore,
            completenessScore:
              part.NBest[0].PronunciationAssessment.CompletenessScore,
            pronScore: part.NBest[0].PronunciationAssessment.PronScore,
            words: part.NBest[0].Words,
            wordCount: part.NBest[0].Words.length,
          };
        } else {
          return {
            recognitionStatus: part.RecognitionStatus,
            offset: part.Offset,
            duration: part.Duration,
            displayText: part.DisplayText,
            confidence: part.NBest[0].Confidence,
            words: part.NBest[0].Words,
            wordCount: part.NBest[0].Words?.length
              ? part.NBest[0].Words?.length
              : 0,
          };
        }
      } else {
        let x = {
          recognitionStatus: part.RecognitionStatus,
          offset: part.Offset,
          duration: part.Duration,
          displayText: part.DisplayText,
        };
        return [];
      }
    });

    parts = parts.filter((x) => x.wordCount > 0);

    // console.log("parts", JSON.stringify(parts));

    aggregateScore = {
      start: Math.min(...parts.flatMap((e) => e?.offset)),
      end: Math.max(...parts.flatMap((e) => e?.offset + e?.duration)),

      confidence: average(
        parts.flatMap((e) => (e?.confidence ? e?.confidence : []))
      ).toFixed(2),
      accuracyScore: average(
        parts.flatMap((e) => (e?.accuracyScore ? e?.accuracyScore : []))
      ).toFixed(0),
      fluencyScore: average(
        parts.flatMap((e) => (e?.fluencyScore ? e?.fluencyScore : []))
      ).toFixed(0),
      completenessScore: average(
        parts.flatMap((e) => (e?.completenessScore ? e?.completenessScore : []))
      ).toFixed(0),
      pronScore: average(
        parts.flatMap((e) => (e?.pronScore ? e?.pronScore : []))
      ).toFixed(0),
      audioDuration: $audioControlDuration,
      duration: undefined,
      wordDurationSum: undefined,
      wordCount: undefined,
      wordPerMinute: undefined,
      wordPerMinuteWordsOnly: undefined,
    };

    aggregateScore.audioDuration = $audioControlDuration;
    aggregateScore.duration = (
      (aggregateScore?.end - aggregateScore?.start) /
      10000000
    ).toFixed(2);
    aggregateScore.wordDurationSum = (
      sum(
        parts
          .flatMap((e) => (e?.words.length ? e.words : []))
          .map((w) => w.Duration)
      ) / 10000000
    ).toFixed(2);

    aggregateScore.wordCount = sum(parts.map((w) => w.wordCount));

    aggregateScore.wordPerMinute = (
      (aggregateScore.wordCount / aggregateScore.duration) *
      60
    ).toFixed(0);

    aggregateScore.wordPerMinuteWordsOnly = (
      (aggregateScore.wordCount / aggregateScore.wordDurationSum) *
      60
    ).toFixed(0);

    aggregateScore = aggregateScore;
    console.log("aggregateScore", JSON.stringify(aggregateScore));
  }
</script>

<div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col">
        <div class="exercise-title">
          {#if exerciseDetail}
            <span class="exercise-name" />{exerciseDetail?.name}
            ({exerciseDetail?.exerciseType})
          {/if}
        </div>
      </div>
      <div class="col-auto lesson-detail-box">
        <div class="lesson-detail float-right">
          <div>Assessment: {$assessmentId}</div>
          <div style="display: none;">Instance: {instanceId}</div>
          <div>{$_("USER_NAME")}: {$userName}</div>
          <div style="display: none;">UserId: {$userId}</div>
          <div style="display: none;">State: {$assessmentState}</div>
        </div>
      </div>
    </div>

    {#if !exerciseDetail}
      <div class="row justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    {:else}
      <Instructions exerciseType={exerciseDetail.exerciseType} />
      <div class="row">
        <div class="col">
          <div class="jumbotron">
            {#if exerciseDetail.exerciseType === "READ"}
              <p class="textToRead">{exerciseDetailData.text}</p>
            {:else if exerciseDetail.exerciseType === "QUESTION"}
              <p class="textToRead">{@html exerciseDetailData.question}</p>
            {:else if exerciseDetail.exerciseType === "MULTICHOICE"}
              <p class="textToRead">{exerciseDetailData.question}</p>

              <ol class="list-group list-group-numbered">
                {#each exerciseDetailData?.options as o, i}
                  <li class="list-group-item">
                    {#if o?.key}{o.key}{:else}{i + 1}{/if}: {o?.text}
                  </li>
                {/each}
              </ol>
            {:else if exerciseDetail.exerciseType === "LISTEN"}
              <p class="textToRead">{exerciseDetailData.question}</p>
              <div class="float-left">
                <TextToSpeech2
                  bind:this={ttsInstance}
                  textToRead={exerciseDetailData.text}
                  language={exerciseDetail.language}
                />
              </div>
            {:else if exerciseDetail.exerciseType === "IMAGE"}
              <div align="center">
                <div class="mb-2">
                  <img
                    class="customImage"
                    src={"https://storage.googleapis.com/sensay-assessment/images/" +
                      exerciseDetailData.imageFileName}
                    alt="Question"
                  />
                </div>
              </div>
              <p class="textToRead">{exerciseDetailData.question}</p>
            {:else if exerciseDetail.exerciseType === "VIDEO"}
              <iframe
                title="youtube video"
                width="500"
                height="350"
                src={exerciseDetailData.youtubeUrl}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
              <h3 class="textToRead">{exerciseDetailData.question}</h3>
            {:else}Wrong Type
            {/if}
            {#if textToRead}
              <!-- <TextToSpeech textToRead={textToRead} language={exerciseDetail.language}></TextToSpeech> -->
              <div class="float-right">
                <TextToSpeech2
                  {textToRead}
                  language={exerciseDetail.courseLanguage}
                />
              </div>
            {/if}
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div style="display:none;" class="col">
          {ttsButtonState} - {ttsToken}
        </div>
        <div class="col col-auto">
          {#if ttsButtonState === "starting"}...
          {:else if ttsButtonState === "error"}STT Error
          {:else if ttsButtonState === "ready"}
            <button
              class="btn btn-primary btn-lg"
              style="font-size: 32px;"
              on:click={startRecognition}
              ><i
                class="fas fa-microphone-alt pr-3"
                style="font-size: 32px;"
              />{$_("START")}</button
            >
          {:else if ttsButtonState === "wait"}
            <button
              class="btn btn-primary btn-lg"
              style="font-size: 32px;"
              on:click={stopRecognition}
              ><i
                class="fas fa-microphone-alt pr-3"
                style="font-size: 32px;"
              />{$_("STOP")}</button
            >
          {:else if ttsButtonState === "recording"}
            <button
              class="btn btn-primary btn-lg"
              style="font-size: 32px;"
              on:click={stopRecognition}
              ><i
                class="fas fa-microphone-alt pr-3"
                style="font-size: 32px;"
              />{$_("STOP")}</button
            >
          {:else if ttsButtonState === "timeout"}
            {$_("PROCESSING")}
          {:else if ttsButtonState === "error"}
            <button
              class="btn btn-primary btn-lg"
              style="font-size: 32px;"
              on:click={submitRetry}
              ><i
                class="fas fa-microphone-alt pr-3"
                style="font-size: 32px;"
              />Refresh</button
            >
          {/if}
          <!-- {#if ttsButtonState === "stop" || ttsButtonState === "done"}
            <button
              class="btn btn-primary btn-lg"
              style="font-size: 32px;"
              on:click={submitResult}
              ><i
                class="fas fa-check pr-3"
                style="font-size: 32px;"
              />Submit</button
            >
          {/if} -->
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col col-auto">
          <SpeechToTextOpus
            time={exerciseDetail.durationLimit}
            bind:this={speechToTextOpus}
            on:audioControlDuration={(event) => {
              $audioControlDuration = event.detail.audioControlDuration;
              formatResult($recognitionResultsFinal);
            }}
            on:recordingStop={() => {
              stopRecognitionTimeout();
            }}
          />
        </div>
      </div>

      {#if ttsButtonState === "done"}
        <div class="row" style="display: none;">
          <div class="col">
            <div class="card">
              <div class="card-header">
                DEBUG Result: <div style="display:none;">
                  {#key aggregateScore}{JSON.stringify(aggregateScore)}{/key}
                </div>
              </div>
              <div class="card-body">
                <div id="resultDivContent">
                  <div class="score">
                    Confidence:
                    <span>{aggregateScore?.confidence}</span>
                  </div>
                  <div class="score">
                    Accuracy:
                    <span>{aggregateScore?.accuracyScore}</span>
                  </div>
                  <div class="score">
                    Fluency:
                    <span>{aggregateScore?.fluencyScore}</span>
                  </div>
                  <div class="score">
                    Completeness:
                    <span>{aggregateScore?.completenessScore}</span>
                  </div>

                  <div class="score">
                    Pronunciation:
                    <span>{aggregateScore?.pronScore}</span>
                  </div>

                  <div class="score">
                    Audio Duration:
                    <span>{aggregateScore?.audioDuration}</span>
                  </div>

                  <div class="score">
                    STT Duration:
                    <span>{aggregateScore?.duration}</span>
                  </div>

                  <div class="score">
                    Word Duration Sum:
                    <span>{aggregateScore?.wordDurationSum}</span>
                  </div>

                  <div class="score">
                    Word Count:
                    <span>{aggregateScore?.wordCount}</span>
                  </div>

                  <div class="score">
                    Word Per Minute (text only):
                    <span>{aggregateScore?.wordPerMinute}</span>
                  </div>

                  <div class="score">
                    Word Per Minute (words only):
                    <span>{aggregateScore?.wordPerMinuteWordsOnly}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3" style="display: none;">
          <div class="col">
            <div class="card">
              <div class="card-header">DEBUG Parts:</div>
              <div class="card-body">
                {#each parts as p, i}
                  <div>
                    {i + 1} - {#if p.confidence}
                      {p?.confidence.toFixed(2)}
                    {/if} - {p.displayText}
                    <span style="display: none;">{JSON.stringify(p)}</span>
                  </div>
                {/each}
              </div>
            </div>
          </div>
        </div>
      {/if}
    {/if}
  </div>
</div>

<style>
  @media (min-width: 768px) {
    .customImage {
      max-width: 600px;
      max-height: 600px;
    }
  }

  @media (max-width: 768px) {
    .customImage {
      max-width: 100%;
    }
  }
</style>
